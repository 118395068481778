const photos = ['larissa.png', 'fernanda.png', 'mariana.png'];
const names = ['Larissa', 'Fernanda', 'Mariana']
const position = Math.floor(Math.random() * photos.length)

function pickName () {
    if (!window.leadfyChatbot.botName){
        return names[position]
    }

    const name = window.leadfyChatbot.botName
    return name
}

function pickPhoto () {
    if (!window.leadfyChatbot.botImage){
        return `${window.LEADFY_CB_ENV.appBaseUrl}/avatars/` + photos[position]
    }
    
    const image = window.leadfyChatbot.botImage
    return image
}


export const botPhoto = pickPhoto();
export const botName = pickName();