import styled from 'styled-components';
import { scale } from '../react-simple-chatbot/lib/common/animations';
import defaultTheme from '../react-simple-chatbot/lib/theme';

const ZapBubble = styled.div`

    animation: ${scale} 0.3s ease forwards;
    background: ${props => (props.user ? props.theme.userBubbleColor : props.theme.botBubbleColor)};
    border-radius: 50px;
    }};
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    color: ${props => (props.user ? props.theme.userFontColor : props.theme.botFontColor)};
    display: inline-block;
    font-size: ${props => (props.user ? '15px' : '14px')};
    font-weight: 400;
    min-height: 20px;
    max-height: 250px;
    min-width: 150px;
    max-width: 70%;
    margin: ${props => {
        const { isFirst, showAvatar, user } = props;

        if (!isFirst && showAvatar) {
            return user ? '-8px 46px 10px 0' : '0px 0 10px 46px';
        }

        if (!isFirst && !showAvatar) {
            return user ? '-8px 0px 10px 0' : '-8px 0 10px 0px';
        }

        return '0 0 10px 0';
    }};
    overflow: hidden;
    position: relative;
    padding: 16px 24px;
    margin-bottom: 0px;
    transform: scale(0);
    transform-origin: ${props => {
        const { isFirst, user } = props;

        if (isFirst) {
            return user ? 'bottom right' : 'bottom left';
        }

        return user ? 'top right' : 'top left';
    }};

    @media screen and (max-width: 568px)  {
        font-size: 15px;
    }
`;

ZapBubble.defaultProps = {
    theme: defaultTheme
};

export default ZapBubble;
