const emailTemplate = {
    inputAttributes: { type: 'email', autofill: 'email' },
    validator: (value) => {
        if (!/.+@.+\..+/.test(value)) {
            return `'${value}' é um email inválido.`
        }
        return true;
    }

}

export default emailTemplate;
