
function subscribeBotEvents(){
    if(window.leadfyChatbot.debugEvents)
        debugBotEvents()
}

function debugBotEvents(){
    window.addEventListener('leadfy_chatbot:init', (e) => console.log("leadfy_chatbot:init"))
    window.addEventListener('leadfy_chatbot:fill', (e) => console.log("leadfy_chatbot:fill", e.detail))
    window.addEventListener('leadfy_chatbot:finish', (e) => console.log("leadfy_chatbot:finish", e.detail))
}

function unsubscribeBotEvents(){
    if(window.leadfyChatbot.debugBotEvents)
        removeDebugBotEvents()
}

function removeDebugBotEvents(){
    window.removeEventListener('leadfy_chatbot:init')
    window.removeEventListener('leadfy_chatbot:fill')
    window.removeEventListener('leadfy_chatbot:finish')
}

function publishBotInitEvent(){
    const eventBotInit = new CustomEvent("leadfy_chatbot:init", {
        bubbles: true,
    });
    window.dispatchEvent(eventBotInit);
}

function publishBotFillEvent(data){
    const eventBotFill = new CustomEvent("leadfy_chatbot:fill", {
        bubbles: true,
        detail: data
    });
    window.dispatchEvent(eventBotFill);
}

function publishBotFinishEvent(data){
    const eventBotFinish = new CustomEvent("leadfy_chatbot:finish", {
        bubbles: true,
        detail: data
    });
    window.dispatchEvent(eventBotFinish);
}




export {subscribeBotEvents, unsubscribeBotEvents, publishBotInitEvent, publishBotFillEvent, publishBotFinishEvent};