import styled from 'styled-components';

const ChatStepContainer = styled.div`
  background: transparent;
  display: flex;
  margin: 8px 0 0 57px;
  padding: 0;
`;

export default ChatStepContainer;
