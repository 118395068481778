import phoneTemplate from "./phone_template"
import emailTemplate from "./email_template"
import priceTemplate from "./price_template"
import AgreementComponent from "./AgreementComponent";
import ZapComponent from "./ZapComponent";

class BrushSteps {
    brush(steps){
      for (let i = 0; i < steps.length; i++) {
        const step = steps[i];
  
        if (step._js_template) {
          steps[i] = {...step, ...this.addTemplate(step._js_template)}
          delete steps[i]._js_template
        }
  
        if (step._component) {
          steps[i] = {...step, component: this.addComponent(step._component)}
          delete steps[i]._component
        }
      }
      return steps
    }
  
    addTemplate(name){
      switch (name){
        case "phone":
          return phoneTemplate;   
  
        case "email":
          return emailTemplate;
        
        case "price":
          return priceTemplate;

        default:
          return {};
      }
    }
  
    addComponent(conf){
      switch (conf.name) {
        case 'AgreementComponent':
          return <AgreementComponent {...conf.props} />

        case 'ZapComponent':
          return <ZapComponent {...conf.props} />
      
        default:
          return {};
      }
    }
}

export default BrushSteps;