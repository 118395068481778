import styled from 'styled-components';
import defaultTheme from '../../theme';

// background: #3AC9C8;  
const OptionElement = styled.button`
  background: ${props => props.theme.userBubbleColor};  
  border: 0;
  border-radius: 22px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  color: white;
  display: inline-block;
  font-size: 14px;
  padding: 12px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
  &:active,
  &:hover:focus {
    outline:none;
  }

  @media screen and (max-width: 568px)  {
    font-size: 15px;
  }
`;

OptionElement.defaultProps = {
  theme: defaultTheme
};

export default OptionElement;
