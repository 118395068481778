import styled from 'styled-components';

const Footer = styled.div`
  position: sticky;
  box-sizing: border-box !important;
  bottom: 40px;  
  overflow: visible;

  @media screen and (max-width: 568px)  {
    bottom: 0;
  }
`;

export default Footer;
