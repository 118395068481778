const priceTemplate = {
    useMask: true,
    patterns: [ 
        'R$ 9,99',
        'R$ 99,99',
        'R$ 999,99',
        'R$ 9.999,99',
        'R$ 99.999,99',
        'R$ 999.999,99',
        'R$ 9.999.999,99',
        'R$ 99.999.999,99',
        'R$ 999.999.999,99',
        'R$ 9.999.999.999,99'
    ],
    inputAttributes: { type: 'text', inputmode: 'decimal'}
}

export default priceTemplate;