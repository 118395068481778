window.LEADFY_CB_ENV = {
  'production': {
    apiBaseUrl: 'https://chatbot.leadfy.xyz',
    appBaseUrl: 'https://chatbot-app.leadfy.xyz',
  },
  'stage': {
    apiBaseUrl: 'https://chatbot.leadfy.xyz',
    appBaseUrl: 'https://chatbot-app-stage.leadfy.xyz',
  },
  'development': {
    apiBaseUrl: 'http://localhost:8080',
    appBaseUrl: 'http://localhost:8081',
  },
  'test': {
    apiBaseUrl: 'http://localhost:8080',
    appBaseUrl: 'http://localhost:8081',
  },
}[process.env.REACT_APP_ENVIRONMENT]
