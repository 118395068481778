import React, { Component } from 'react';
import '../css/ZapComponent.css'
import ZapBubble from './ZapBubble';

class ZapComponent extends Component {
  constructor(props) {
    super(props)

  }

  onOptionClick = () => {
    window.open(`https://wa.me/${this.props.phone}?text=Olá, tenho interesse em mais informações!`, '_blank', 'noopener,noreferrer');
    this.triggerNextStep()
  }
  

  triggerNextStep = () => {
    this.props.triggerNextStep({
      value: this.props.value,
      trigger: this.props.trigger, 
    })
  }
  
  render() {
    return (     
      <div>
        <div className="cst_btn_div">
          <ZapBubble>
            <div>
                <div className='zap_txt'>
                  {this.props.text}
                </div>
                <div className="button_div">
                  <div className="option_button">
                    <button className="zapBtn"
                      onClick={() => this.onOptionClick()}
                    >
                      <img className="custom_button_icon" src={`${window.LEADFY_CB_ENV.appBaseUrl}/images/icons8-whatsapp.svg`}></img>
                      <span>WhatsApp</span>
                    </button>
                  </div>
                </div>
            </div>
          </ZapBubble>
        </div>
      </div>
    )
  }
}

export default ZapComponent;

