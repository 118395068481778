import React, { Component } from 'react';
import '../css/AgreementComponent.css'
import Bubble from '../react-simple-chatbot/lib/steps_components/text/Bubble'
import AgreementBubble from './AgreementBubble';
import { publishBotInitEvent } from './botEvents'

class AgreementComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showButton: true
    }
  }
    
  onOptionClick = () => {
    this.setState({showButton: false})
    publishBotInitEvent()
    this.setSession()
  }
  
  setSession = () => {
    fetch(
      `${window.LEADFY_CB_ENV.apiBaseUrl}/init_session`, 
      {
        method: "POST",
        body: JSON.stringify({
          chatbot: window.leadfyChatbot.chatbot,
          url: window.location.href
        }),
        headers: {'Content-Type': 'application/json'},
      }
      ).then(resp => resp.json())
      .then(resp => {
        window.leadfyChatbot.session = resp.session
        this.triggerNextStep()
      }).catch(err => console.error(err))   
  }

  triggerNextStep = () => {
    this.props.triggerNextStep({ 
      value: this.props.value, 
      trigger: this.props.trigger 
    })
  }
  
  render() {
    return (     
      <div>
        {this.state.showButton &&
          <div className="cst_btn_div">
            <AgreementBubble>
                <div>
                  <div className='agreement_txt'>
                    {this.props.text}
                  </div>
                  <div className="button_div">
                    <div className="option_button">
                      <button className="styleBtn"
                        onClick={() => this.onOptionClick()}
                      >
                      {this.props.value}
                        <img className="custom_button_arrow" src={`${window.LEADFY_CB_ENV.appBaseUrl}/images/Arrow.svg`}></img>
                      </button>
                    </div>
                  </div>
                  <div className="policy_txt"> 
                    <p>Clicando acima você aceita nossa <a 
                      href='https://leadfy-imob.com.br/politica-de-privacidade.html' rel="nofollow">Politica de privacidade</a>
                    </p>
                  </div>
                </div>
              </AgreementBubble>
          </div>
          ||
          <Bubble
            className="rsc-ts-bubble"
            user={false}
            showAvatar={false}
            isFirst={false}
            isLast={false}
          >
            {this.props.text}
          </Bubble>
        }
      </div>
    )
  }
}

export default AgreementComponent;

