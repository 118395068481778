import './css/App.css';
import SimpleForm from './scripts/chat';

function App() {
  return (
    <SimpleForm />
  );
}

export default App;
