import React, { Component } from 'react';
import '../css/header.css';
import {botPhoto, botName} from './utils';

class Header extends Component {
  render() {
    return (
      <div className="header_ct">
        <div className="bot_avatar_ct">
          <img className="bot_img" alt="foto do atendente do chat bot" src={botPhoto}/>
        </div>
        <div className="bot_info_ct">
          <p className="bot_info_title">{botName}</p>
          <p className="bot_info_subtitle">online</p>
        </div>
        <div className="close_btn_ct" alt="Close Button">
          <button className="close_btn" onClick={() => this.props.toggleFloating(false) }>
            <img className="close_btn_img"/>
          </button>
        </div>
      </div>
    );
  }
}

export default Header
