import styled from 'styled-components';

// height: calc(${props => props.height} - ${props => (props.hideInput ? '56px' : '112px')});
const Content = styled.div`
  height: ${props => (props.floating ? 'calc(100% - 124px)' : '')};
  overflow-y: scroll;
  margin-top: 2px;
  padding-top: 6px;
`;

export default Content;
