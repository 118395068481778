import React from 'react';
import {botPhoto} from '../../../scripts/utils'

const ChatIcon = () => (
  <div class='bot_floating_ct'>
    <img class='bot_floating_img' src={botPhoto}></img>
  </div>
);

export default ChatIcon; 

